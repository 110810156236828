import React, { useState } from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import OwlCarousel from 'react-owl-carousel';



function Cform() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-left">
                    <h5>Review Here</h5>
                </div>
            </div>
        <div className="Toastify" />
        <div className="form-row">
            <div className="col-12 mt-2">
                <textarea className="form-control" placeholder="Write a Review" id="comment" defaultValue={""} />
            </div>
            <div className="col-12 mt-2 text-right">
                <button className="btn btn-sm btn-danger">Post</button>
                </div>
                </div>
                <div />
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="dreview">
                            <div className="alert alert-info">There is no review</div>
                            </div></div></div>
        </div>

    );
}

const Viewdetail = () => {

    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

        // 👇️ or simply set it to true
        // setIsShown(true);
    };

    return (
        <>
            <Header />
            <section className="hero hero-normal">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="hero__categories">
                                <div className="hero__categories__all">
                                    <i className="fa fa-bars" />
                                    <span>All departments</span>
                                </div>
                                <ul>
                                    <li><a href="#">Fresh Meat</a></li>
                                    <li><a href="#">Vegetables</a></li>
                                    <li><a href="#">Fruit &amp; Nut Gifts</a></li>
                                    <li><a href="#">Fresh Berries</a></li>
                                    <li><a href="#">Ocean Foods</a></li>
                                    <li><a href="#">Butter &amp; Eggs</a></li>
                                    <li><a href="#">Fastfood</a></li>
                                    <li><a href="#">Fresh Onion</a></li>
                                    <li><a href="#">Papayaya &amp; Crisps</a></li>
                                    <li><a href="#">Oatmeal</a></li>
                                    <li><a href="#">Fresh Bananas</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="hero__search">
                                <div className="hero__search__form">
                                    <form action="#">
                                        <div className="hero__search__categories">
                                            All Categories
                                            <span className="arrow_carrot-down" />
                                        </div>
                                        <input type="text" placeholder="What do yo u need?" />
                                        <button type="submit" className="site-btn">SEARCH</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Hero Section End */}

            {/* Product Details Section Begin */}
            <section className="product-details spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="product__details__pic">
                                <div className="product__details__pic__item">
                                    <img className="product__details__pic__item--large" src="img/featured/f6.jpg" alt="" />
                                </div>
                                <div className="product__details__pic__slider owl-carousel">
                                    <img data-imgbigurl="img/product/details/product-details-2.jpg" src="img/featured/f6.jpg" alt="" />
                                    <img data-imgbigurl="img/product/details/product-details-3.jpg" src="img/product/details/thumb-2.jpg" alt="" />
                                    <img data-imgbigurl="img/product/details/product-details-5.jpg" src="img/product/details/thumb-3.jpg" alt="" />
                                    <img data-imgbigurl="img/product/details/product-details-4.jpg" src="img/product/details/thumb-4.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="product__details__text text-left">
                                <h3>Brainamrit Syrup</h3>
                                <div className="product__details__rating">
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star" />
                                    <i className="fa fa-star-half-o" />
                                    <span>(18 reviews)</span>
                                </div>
                                <div className="product__details__price">$50.00</div>
                                <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vestibulum ac diam sit amet quam
                                    vehicula elementum sed sit amet dui. Sed porttitor lectus nibh. Vestibulum ac diam sit amet
                                    quam vehicula elementum sed sit amet dui. Proin eget tortor risus.</p>
                                <div className="product__details__quantity">
                                    <div className="quantity">
                                        <div className="pro-qty">
                                            <input type="text" defaultValue={1} />
                                        </div>
                                    </div>
                                </div>
                                <a href="#" className="primary-btn">ADD TO CARD</a>
                                <a href="#" className="heart-icon"><span className="icon_heart_alt" /></a>
                                <ul style={{ textAlign: 'left' }}>
                                    <li><b>Availability</b> <span>In Stock</span></li>
                                    <li><b>Shipping</b> <span>01 day shipping. <samp>Free pickup today</samp></span></li>
                                    <li><b>Weight</b> <span>0.5 kg</span></li>
                                    <li><b>Share on</b>
                                        <div className="share">
                                            <a href="#"><i className="fa fa-facebook" /></a>
                                            <a href="#"><i className="fa fa-twitter" /></a>
                                            <a href="#"><i className="fa fa-instagram" /></a>
                                            <a href="#"><i className="fa fa-pinterest" /></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className='row'>
                                <div className='col-md-12 text-left'>
                                    <div class="hidden-xs pt-5">
                                        <div class="form-group">
                                            <h5 className='pb-3'>Rating & Reviews</h5>
                                            <ul class="list-group">
                                                <li class="list-group-item"><a href="#">★★★★★

                                                    (1247)</a></li>
                                                <li class="list-group-item"><a href="#">★★★★☆

                                                    (47)</a></li>
                                                <li class="list-group-item"><a href="#">★★★☆☆

                                                    (27)</a></li>
                                                <li class="list-group-item"><a href="#">★★☆☆☆

                                                    (24)</a></li>
                                                <li class="list-group-item"><a href="#">★☆☆☆☆

                                                    (1)</a></li>
                                            </ul>
                                        </div>
                                        <div class="form-group"><button class="btn btn-success btn-block btn-sm" href="#" onClick={handleClick}>Write a Review</button></div>
                                    </div>
                                </div>
                                {isShown && (
                                    <div>
                                    </div>
                                )}

                                {/* 👇️ show component on click */}
                                {isShown && <Cform />}

                                <div className='col-md-12 text-left'>
                                    <div class="testimonial-heading">
                                        <span>Comments</span>
                                    </div>
                                    <div class="testimonial-box-container">
                                        <div class="testimonial-box">
                                            <div class="box-top">
                                                <div class="profile">
                                                    <div class="profile-img">
                                                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                                                    </div>
                                                    <div class="name-user">
                                                        <strong>John Doe</strong>
                                                        <span>@johndoe</span>
                                                    </div>
                                                </div>
                                                <div class="reviews">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </div>
                                            </div>
                                            <div class="client-comment">
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="testimonial-box-container">
                                        <div class="testimonial-box">
                                            <div class="box-top">
                                                <div class="profile">
                                                    <div class="profile-img">
                                                        <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                                                    </div>
                                                    <div class="name-user">
                                                        <strong>John Doe</strong>
                                                        <span>@johndoe</span>
                                                    </div>
                                                </div>
                                                <div class="reviews">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </div>
                                            </div>
                                            <div class="client-comment">
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>

                    </div>
                </div>
            </section>
            {/* Product Details Section End */}
            {/* Related Product Section Begin */}
            <section className="related-product">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title related__product__title">
                                <h2>Related Product</h2>
                            </div>
                        </div>
                    </div>
                    <OwlCarousel items={3}

                        className="owl-theme"
                        loop

                        nav

                        margin={8} >

                        <div className="col">
                            <div className="categories__item set-bg">
                                <img className="img" src="img/featured/f6.jpg" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="categories__item set-bg">
                                <img className="img" src="img/featured/f1.jpg" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="categories__item set-bg">
                                <img className="img" src="img/featured/f2.jpg" />
                            </div>
                        </div>

                        <div className="col">
                            <div className="categories__item set-bg">
                                <img className="img" src="img/featured/f3.jpeg" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="categories__item set-bg">
                                <img className="img" src="img/featured/f4.jpg" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="categories__item set-bg">
                                <img className="img" src="img/featured/f5.jpg" />
                            </div>
                        </div>

                    </OwlCarousel>
                </div>
            </section>
            <section className='comment-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 text-left'>
                            <div class="hidden-xs">
                                <div class="form-group">
                                    <h5>Rating</h5>
                                    <ul class="list-group">
                                        <li class="list-group-item"><a href="#">★★★★★

                                            (1247)</a></li>
                                        <li class="list-group-item"><a href="#">★★★★☆

                                            (47)</a></li>
                                        <li class="list-group-item"><a href="#">★★★☆☆

                                            (27)</a></li>
                                        <li class="list-group-item"><a href="#">★★☆☆☆

                                            (24)</a></li>
                                        <li class="list-group-item"><a href="#">★☆☆☆☆

                                            (1)</a></li>
                                    </ul>
                                </div>
                                <div class="form-group"><div class="btn btn-success btn-block btn-sm" href="#">Write a Review</div></div>
                            </div>
                        </div>
                        <div className='col-md-8 text-left'>
                            <div class="testimonial-heading">
                                <span>Comments</span>
                            </div>
                            <div class="testimonial-box-container">
                                <div class="testimonial-box">
                                    <div class="box-top">
                                        <div class="profile">
                                            <div class="profile-img">
                                                <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                                            </div>
                                            <div class="name-user">
                                                <strong>John Doe</strong>
                                                <span>@johndoe</span>
                                            </div>
                                        </div>
                                        <div class="reviews">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                    </div>
                                    <div class="client-comment">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="testimonial-box-container">
                                <div class="testimonial-box">
                                    <div class="box-top">
                                        <div class="profile">
                                            <div class="profile-img">
                                                <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                                            </div>
                                            <div class="name-user">
                                                <strong>John Doe</strong>
                                                <span>@johndoe</span>
                                            </div>
                                        </div>
                                        <div class="reviews">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                    </div>
                                    <div class="client-comment">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Viewdetail;