import React from 'react'


const Footer = () => {
    return (
        <>
            <footer className="footer spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer__about">
                                <div className="footer__about__logo">
                                    <a href="./index.html"><h3 className='text-success'>Amritansh</h3></a>
                                </div>
                                <ul style={{textAlign:'left'}}>
                                    <li><strong>Address:</strong> Amritansh Ayurveda, 5BC-82, Sector 5 , Vrindavan Colony</li>
                                    <li>Raibarely Road, Lucknow, Uttar Pradesh, India. Pincode: 226014</li>
                                    <li><strong>Phone:</strong> +91 8130650023</li>
                                    <li><strong>Email:</strong> info@amritanshayurveda.com</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-9 col-sm-6 ">
                            <div className="footer__widget">
                                <ul style={{textAlign:'left'}}>
                                <h6>Information</h6>
                                    <li><a href="#">What is Ayurveda</a></li>
                                    <li><a href="#">About Us</a></li>
                                    <li><a href="#">Shipping and Delivery</a></li>
                                    <li><a href="#">Media Gallery</a></li>
                                </ul>
                                <ul style={{textAlign:'left'}}>
                                <h6>My Account</h6>
                                    <li><a href="#">My Account</a></li>
                                    <li><a href="#">Order History</a></li>
                                    <li><a href="#">Track Order</a></li>
                                </ul>
                                {/*  */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer__widget">
                                <ul style={{textAlign:'left'}}>
                                <h6>Customer Service</h6>
                                    <li><a href="#">Terms and Conditions</a></li>
                                    <li><a href="#">Privacy policy</a></li>
                                    <li><a href="#">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer__copyright">
                                <div className="footer__copyright__text"><p>
                                Copyright © 2021 Amritansh Ayurveda. All rights reserved.</p></div>
                                {/* <div className="footer__copyright__payment"><img src="img/payment-item.png" alt="" /></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section End */}
        </>
    )
}
export default Footer;