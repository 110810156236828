import './App.css';
import './pages/css/style.css';
// import './pages/css/bootstrap.min.css';
// import './pages/css/slicknav.min.css';
import {
  BrowserRouter,
  Routes,
  Route,} from "react-router-dom";


import Home from './pages/home/Home';
import Cart from './pages/cart/Cart';
import Checkout from './pages/checkout/Checkout';
import Viewdetail from './pages/view-detail/Viewdetail';
import Shopgrid from './pages/shop-grid/Shopgrid';
// import "../node_modules/jquery/dist/jquery.min.js";
// import "../src/images/css/bootstrap.min.css";
// import "../src/images/css/jquery-ui.min.css";
// import "../src/images/css/elegant-icons.css";
// import "../src/images/css/owl.carousel.min.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/Checkout" element={<Checkout />} />
          <Route exact path="/view-detail" element={<Viewdetail />} />
          <Route exact path="/shop-grid" element={<Shopgrid />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
