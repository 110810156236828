import React from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import OwlCarousel from 'react-owl-carousel';
import h1 from '../../images/mental.jpg';
import h2 from '../../images/piles.jpg';
import h3 from '../../images/stomach.jpg';
import h4 from '../../images/weight.jpg';
import C1 from '../../images/c1.jpg';
import C2 from '../../images/c2.jpg';
import C3 from '../../images/c3.jpg';
import C4 from '../../images/guggul.jpeg';
import C5 from '../../images/mandura.jpeg';
const Shopgrid = () => {
    return (
        <>
        <Header/>
            <section className="hero ">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-12">
                            <div className="hero__search">
                                <div className="hero__search__form" style={{width : '100%'}}>
                                    <form action="#">
                                        <div className="hero__search__categories">
                                            All Categories
                                            <span className="arrow_carrot-down" />
                                        </div>
                                        <input type="text" placeholder="What do yo u need?" />
                                        <button type="submit" className="site-btn">SEARCH</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Hero Section End */}
            
            <section className="products">
                <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-5 p-2">
                    <div className="sidebar text-left">
                        <div className="sidebar__item" style={{border: '1px solid #eee'}}>
                        <h4 className='text-left text-white p-2 bg-system'>Select Category</h4>
                        <ul className='text-left p-2'>
                            <li><a href="#">Fresh Meat</a></li>
                            <li><a href="#">Vegetables</a></li>
                            <li><a href="#">Fruit &amp; Nut Gifts</a></li>
                            <li><a href="#">Fresh Berries</a></li>
                            <li><a href="#">Ocean Foods</a></li>
                            <li><a href="#">Butter &amp; Eggs</a></li>
                            <li><a href="#">Fastfood</a></li>
                            <li><a href="#">Fresh Onion</a></li>
                            <li><a href="#">Papayaya &amp; Crisps</a></li>
                            <li><a href="#">Oatmeal</a></li>
                        </ul>
                        </div>
                        <div className="sidebar__item">
                        <h4>Price</h4>
                        <div className="price-range-wrap">
                            <div className="price-range ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content" data-min={10} data-max={540}>
                            <div className="ui-slider-range ui-corner-all ui-widget-header" />
                            <span tabIndex={0} className="ui-slider-handle ui-corner-all ui-state-default" />
                            <span tabIndex={0} className="ui-slider-handle ui-corner-all ui-state-default" />
                            </div>
                            <div className="range-slider">
                            <div className="price-input">
                                <input type="text" id="minamount" />
                                <input type="text" id="maxamount" />
                            </div>
                            </div>
                        </div>
                        </div>
                        {/* <div className="sidebar__item sidebar__item__color--option">
                        <h4>Colors</h4>
                        <div className="sidebar__item__color sidebar__item__color--white">
                            <label htmlFor="white">
                            White
                            <input type="radio" id="white" />
                            </label>
                        </div>
                        <div className="sidebar__item__color sidebar__item__color--gray">
                            <label htmlFor="gray">
                            Gray
                            <input type="radio" id="gray" />
                            </label>
                        </div>
                        <div className="sidebar__item__color sidebar__item__color--red">
                            <label htmlFor="red">
                            Red
                            <input type="radio" id="red" />
                            </label>
                        </div>
                        <div className="sidebar__item__color sidebar__item__color--black">
                            <label htmlFor="black">
                            Black
                            <input type="radio" id="black" />
                            </label>
                        </div>
                        <div className="sidebar__item__color sidebar__item__color--blue">
                            <label htmlFor="blue">
                            Blue
                            <input type="radio" id="blue" />
                            </label>
                        </div>
                        <div className="sidebar__item__color sidebar__item__color--green">
                            <label htmlFor="green">
                            Green
                            <input type="radio" id="green" />
                            </label>
                        </div>
                        </div> */}
                        <div className="sidebar__item">
                        <h4>Popular Size</h4>
                        <div className="sidebar__item__size">
                            <label htmlFor="large">
                            Large
                            <input type="radio" id="large" />
                            </label>
                        </div>
                        <div className="sidebar__item__size">
                            <label htmlFor="medium">
                            Medium
                            <input type="radio" id="medium" />
                            </label>
                        </div>
                        <div className="sidebar__item__size">
                            <label htmlFor="small">
                            Small
                            <input type="radio" id="small" />
                            </label>
                        </div>
                        <div className="sidebar__item__size">
                            <label htmlFor="tiny">
                            Tiny
                            <input type="radio" id="tiny" />
                            </label>
                        </div>
                        </div>
                        <div className="sidebar__item">
                        <div className="latest-product__text">
                            <h4>Latest Products</h4>
                            <OwlCarousel items={1}

                            className="owl-theme"
                            loop
                            autoplay='true'

                            nav

                            margin={8} >

                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C5} />
                                    <h5><a href="#">Mandura</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C1} />
                                    <h5><a href="#">Arista and Asvata</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C3} />
                                    <h5><a href="#">Arka Kalpana</a></h5>
                                </div>
                            </div>

                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C4} />
                                    <h5><a href="#">Guggul</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C1} />
                                    <h5><a href="#">Arista and Asvata</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C3} />
                                    <h5><a href="#">Kalpana</a></h5>
                                </div>
                            </div>

                        </OwlCarousel>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-9 col-md-7">
                    <OwlCarousel items={3}

                        className="owl-theme"
                        loop

                        nav

                        margin={8} >

                        <div className="col">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                        </div>

                        </OwlCarousel>
                    {/* <div className="product__discount">
                        <div className="section-title product__discount__title">
                        <h2>Sale Off</h2>
                        </div>
                        <div className="row">
                        <div className="product__discount__slider owl-carousel">
                            <div className="col-lg-4">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" data-setbg="img/product/discount/pd-2.jpg">
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Vegetables</span>
                                <h5><a href="#">Vegetables’package</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" data-setbg="img/product/discount/pd-3.jpg">
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Mixed Fruitss</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" data-setbg="img/product/discount/pd-4.jpg">
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" data-setbg="img/product/discount/pd-5.jpg">
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4">
                            <div className="product__discount__item">
                                <div className="product__discount__item__pic set-bg" data-setbg="img/product/discount/pd-6.jpg">
                                <div className="product__discount__percent">-20%</div>
                                <ul className="product__item__pic__hover">
                                    <li><a href="#"><i className="fa fa-heart" /></a></li>
                                    
                                    <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                </ul>
                                </div>
                                <div className="product__discount__item__text">
                                <span>Dried Fruit</span>
                                <h5><a href="#">Raisin’n’nuts</a></h5>
                                <div className="product__item__price">$30.00 <span>$36.00</span></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div> */}
                    <div className="filter__item">
                        <div className="row">
                        <div className="col-lg-4 col-md-5">
                            <div className="filter__sort pull-left">
                            <span>Sort By</span>
                            <select className='select-filter'>
                                <option value={0}>Default</option>
                                <option value={0}>Default</option>
                            </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="filter__found">
                            <h6><span>16</span> Products found</h6>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-3">
                            <div className="filter__option">
                            <span className="icon_grid-2x2" />
                            <span className="icon_ul" />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f3.jpeg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f3.jpeg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="product__item">
                            <div className="product__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                            <ul className="product__item__pic__hover">
                                <li><a href="#"><i className="fa fa-heart" /></a></li>
                                
                                <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                            </ul>
                            </div>
                            <div className="product__item__text">
                            <h6><a href="#">Brain Amrit Syrup</a></h6>
                            <h5>$30.00</h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="product__pagination">
                        <a href="#">1</a>
                        <a href="#">2</a>
                        <a href="#">3</a>
                        <a href="#"><i className="fa fa-long-arrow-right" /></a>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};
export default Shopgrid;