import { React } from 'react'
import $ from 'jquery';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import B1 from '../../images/banner1_1.png';
import B2 from '../../images/ayurved_1.jpg';
import C1 from '../../images/c1.jpg';
import C2 from '../../images/c2.jpg';
import C3 from '../../images/c3.jpg';
import C4 from '../../images/guggul.jpeg';
import C5 from '../../images/mandura.jpeg';
import bg from '../../images/bg.jpeg';
import h1 from '../../images/mental.jpg';
import h2 from '../../images/piles.jpg';
import h3 from '../../images/stomach.jpg';
import h4 from '../../images/weight.jpg';
import h5 from '../../images/women.jpg';
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';

import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {

    const myStyle={
        backgroundImage: 
        "url('https://img.freepik.com/free-photo/3d-leaves-looking-out-green-bokeh-light-landscape_1048-10225.jpg?w=2000')",
        height:'100vh',
        marginTop:'-70px',
        fontSize:'50px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    return (


        <>

            <Header />
            <section className="hero">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="hero__categories">
                                <div className="hero__categories__all">
                                    <i className="fa fa-bars" />
                                    <span>Categories</span>
                                </div>
                                <ul style={{ textAlign: 'left' }}>
                                    <li><a href="#">Avaleha</a></li>
                                    <li><a href="#">Bhasma</a></li>
                                    <li><a href="#">Churna</a></li>
                                    <li><a href="#">Kwath churna</a></li>
                                    <li><a href="#">Lepa</a></li>
                                    <li><a href="#">Lahua</a></li>
                                    <li><a href="#">Parpati</a></li>
                                    <li><a href="#">Taila</a></li>
                                    <li><a href="#">Vati</a></li>
                                    <li><a href="#">Anjana</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="hero__search">
                                <div className='row'>
                                    <div className="col-lg-9">
                                        <div className="hero__search__form">
                                            <form action="#">
                                                <div className="hero__search__categories">
                                                    Categories
                                                    <span className="arrow_carrot-down" />
                                                </div>
                                                <input type="text" placeholder="What do yo u need?" />
                                                <button type="submit" className="site-btn"> <i className="fa fa-search" /></button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="hero__search__phone">
                                            {/* <div className="hero__search__phone__icon">
                                                <i className="fa fa-user" />
                                            </div> <h5>My Account</h5> */}
                                            <div className="hero__search__phone__text">
                                            <span className='text-success'><i className="fa fa-user" /></span><a href='/profile'><h5> My Account</h5></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hero__item set-bg">
                                <img src={B2} className="img-fluid" style={{ height: "100%", width: '100%' }} />
                            </div>
                        </div>
                        {/* <div className='col-lg-2'></div>
                        <div className='col-lg-10'>
                            
                        </div> */}
                    </div>
                </div>
            </section>
            {/* Hero Section End */}
            {/* Categories Section Begin */}
            <section className="categories">
                <div className="container">

                    <div className='row'>
                        <div className='col mb-2'>
                            <div className="section-title">
                                <h2>Shop By Category</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <OwlCarousel items={5}

                            className="owl-theme"
                            loop

                            nav

                            margin={8} >

                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C5} />
                                    <h5><a href="#">Mandura</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C1} />
                                    <h5><a href="#">Arista and Asvata</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C3} />
                                    <h5><a href="#">Arka Kalpana</a></h5>
                                </div>
                            </div>

                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C4} />
                                    <h5><a href="#">Guggul</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C1} />
                                    <h5><a href="#">Arista and Asvata</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C3} />
                                    <h5><a href="#">Kalpana</a></h5>
                                </div>
                            </div>

                        </OwlCarousel>

                    </div>
                </div>
            </section>
            {/* Categories Section End */}
            {/* Featured Section Begin */}
            <section className="featured spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>Featured Product</h2>
                            </div>
                            <div className="featured__controls">
                                <ul>
                                    <li className="active" data-filter="*">All</li>
                                    <li data-filter=".oranges">Oranges</li>
                                    <li data-filter=".fresh-meat">Fresh Meat</li>
                                    <li data-filter=".vegetables">Vegetables</li>
                                    <li data-filter=".fastfood">Fastfood</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row featured__filter">
                        <div className="col-lg-3 col-md-4 col-sm-6 mix oranges fresh-meat">
                            <div className="featured__item">
                                <div className="featured__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f1.jpg')" }} >
                                    <ul className="featured__item__pic__hover">
                                        <li><a href="#"><i className="fa fa-heart" /></a></li>

                                        <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                    </ul>
                                </div>
                                <div className="featured__item__text">
                                    <h6><a href="#">Brainamrit Syrup</a></h6>
                                    <h5>INR 140</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mix vegetables fastfood">
                            <div className="featured__item">
                                <div className="featured__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f2.jpg')" }}>
                                    <ul className="featured__item__pic__hover">
                                        <li><a href="#"><i className="fa fa-heart" /></a></li>

                                        <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                    </ul>
                                </div>
                                <div className="featured__item__text">
                                    <h6><a href="#">Brainamrit Syrup</a></h6>
                                    <h5>INR 140</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mix vegetables fresh-meat">
                            <div className="featured__item">
                                <div className="featured__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f3.jpeg')" }}>
                                    <ul className="featured__item__pic__hover">
                                        <li><a href="#"><i className="fa fa-heart" /></a></li>

                                        <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                    </ul>
                                </div>
                                <div className="featured__item__text">
                                    <h6><a href="#">Brainamrit Syrup</a></h6>
                                    <h5>INR 140</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mix fastfood oranges">
                            <div className="featured__item">
                                <div className="featured__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f4.jpeg')" }}>
                                    <ul className="featured__item__pic__hover">
                                        <li><a href="#"><i className="fa fa-heart" /></a></li>

                                        <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                    </ul>
                                </div>
                                <div className="featured__item__text">
                                    <h6><a href="#">Brainamrit Syrup</a></h6>
                                    <h5>INR 140</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mix fresh-meat vegetables">
                            <div className="featured__item">
                                <div className="featured__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f5.jpg')" }}>
                                    <ul className="featured__item__pic__hover">
                                        <li><a href="#"><i className="fa fa-heart" /></a></li>

                                        <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                    </ul>
                                </div>
                                <div className="featured__item__text">
                                    <h6><a href="#">Brainamrit Syrup</a></h6>
                                    <h5>INR 140</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mix oranges fastfood">
                            <div className="featured__item">
                                <div className="featured__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f6.jpg')" }}>
                                    <ul className="featured__item__pic__hover">
                                        <li><a href="#"><i className="fa fa-heart" /></a></li>

                                        <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                    </ul>
                                </div>
                                <div className="featured__item__text">
                                    <h6><a href="#">Brainamrit Syrup</a></h6>
                                    <h5>INR 140</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mix fresh-meat vegetables">
                            <div className="featured__item">
                                <div className="featured__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f7.jpeg')" }}>
                                    <ul className="featured__item__pic__hover">
                                        <li><a href="#"><i className="fa fa-heart" /></a></li>

                                        <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                    </ul>
                                </div>
                                <div className="featured__item__text">
                                    <h6><a href="#">Brainamrit Syrup</a></h6>
                                    <h5>INR 140</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 mix fastfood vegetables">
                            <div className="featured__item">
                                <div className="featured__item__pic set-bg" style={{ backgroundImage: "url('img/featured/f8.jpeg')" }}>
                                    <ul className="featured__item__pic__hover">
                                        <li><a href="#"><i className="fa fa-heart" /></a></li>

                                        <li><a href="#"><i className="fa fa-shopping-cart" /></a></li>
                                    </ul>
                                </div>
                                <div className="featured__item__text">
                                    <h6><a href="#">Brainamrit Syrup</a></h6>
                                    <h5>INR 140</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Featured Section End */}
            <section className="health-categories set-bg">
                <div className="container">

                    <div className='row'>
                        <div className='col mb-2'>
                            <div className="section-title">
                                <h2>Health Concerns</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <OwlCarousel items={5}

                            className="owl-theme"
                            loop

                            nav

                            margin={8} >

                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={h1} />
                                    <h5><a href="#">Mental Care</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={h2} />
                                    <h5><a href="#">Arista and Asvata</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={h3} />
                                    <h5><a href="#">Piles Care</a></h5>
                                </div>
                            </div>

                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={h4} />
                                    <h5><a href="#">Stomach Care</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={h1} />
                                    <h5><a href="#">Weight Care</a></h5>
                                </div>
                            </div>
                            <div className="col">
                                <div className="categories__item set-bg">
                                    <img className="img" src={C3} />
                                    <h5><a href="#">Women`s Care</a></h5>
                                </div>
                            </div>

                        </OwlCarousel>

                    </div>
                </div>
            </section>
            {/* Banner Begin */}
            <div className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="banner__pic">
                                <img src={"img/banner/banner-1.jpg"} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="banner__pic">
                                <img src="img/banner/banner-2.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Banner End */}
            {/* Latest Product Section Begin */}
            <section className="latest-product spad">
                <div className="container">
                    <div className="row">


                        <div className="col-lg-12">
                            <div className="product__details__tab ssproduct-tabs">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab"
                                            aria-selected="true"><h4>Latest Products</h4></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab"
                                            aria-selected="false"><h4>Top Rated Products</h4></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabs-3" role="tab"
                                            aria-selected="false"><h4>Reviews Product</h4></a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                        <div className="product__details__tab__desc">
                                            <OwlCarousel items={3}

                                                className="owl-theme"
                                                loop

                                                nav

                                                margin={8} >

                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f6.jpg"/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f1.jpg" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f2.jpg" />
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f3.jpeg" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f4.jpg" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f5.jpg" />
                                                    </div>
                                                </div>

                                            </OwlCarousel>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tabs-2" role="tabpanel">
                                    <div className="product__details__tab__desc">
                                            <OwlCarousel items={3}

                                                className="owl-theme"
                                                loop

                                                nav

                                                margin={8} >

<div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f6.jpg"/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f1.jpg" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f2.jpg" />
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f3.jpeg" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f4.jpg" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f5.jpg" />
                                                    </div>
                                                </div>

                                            </OwlCarousel>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tabs-3" role="tabpanel">
                                    <div className="product__details__tab__desc">
                                            <OwlCarousel items={3}

                                                className="owl-theme"
                                                loop

                                                nav

                                                margin={8} >

<div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f6.jpg"/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f1.jpg" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f2.jpg" />
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f3.jpeg" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f4.jpg" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="categories__item set-bg">
                                                        <img className="img" src="img/featured/f5.jpg" />
                                                    </div>
                                                </div>

                                            </OwlCarousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* <div className="col-lg-4 col-md-6">
                            <div className="latest-product__text">
                                <h4>Latest Products</h4>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="latest-product__text">
                                <h4>Top Rated Products</h4>
                                <div className="latest-product__slider owl-carousel">
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-1.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-2.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-3.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-1.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-2.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-3.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="latest-product__text">
                                <h4>Review Products</h4>
                                <div className="latest-product__slider owl-carousel">
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-1.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-2.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-3.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-1.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-2.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="img/latest-product/lp-3.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Crab Pool Security</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* Latest Product Section End */}
            {/* Blog Section Begin */}
            <section className="from-blog spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title from-blog__title">
                                <h2>From The Blog</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="blog__item">
                                <div className="blog__item__pic">
                                    <img src="img/blog/blog-1.jpg" alt="" />
                                </div>
                                <div className="blog__item__text">
                                    <ul>
                                        <li><i className="fa fa-calendar-o" /> May 4,2019</li>
                                        <li><i className="fa fa-comment-o" /> 5</li>
                                    </ul>
                                    <h5><a href="#">Cooking tips make cooking simple</a></h5>
                                    <p>Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="blog__item">
                                <div className="blog__item__pic">
                                    <img src="img/blog/blog-2.jpg" alt="" />
                                </div>
                                <div className="blog__item__text">
                                    <ul>
                                        <li><i className="fa fa-calendar-o" /> May 4,2019</li>
                                        <li><i className="fa fa-comment-o" /> 5</li>
                                    </ul>
                                    <h5><a href="#">6 ways to prepare breakfast for 30</a></h5>
                                    <p>Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="blog__item">
                                <div className="blog__item__pic">
                                    <img src="img/blog/blog-3.jpg" alt="" />
                                </div>
                                <div className="blog__item__text">
                                    <ul>
                                        <li><i className="fa fa-calendar-o" /> May 4,2019</li>
                                        <li><i className="fa fa-comment-o" /> 5</li>
                                    </ul>
                                    <h5><a href="#">Visit the clean farm in the US</a></h5>
                                    <p>Sed quia non numquam modi tempora indunt ut labore et dolore magnam aliquam quaerat </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Blog Section End */}
            <Footer />
        </>
    );
};
export default Home;